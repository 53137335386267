import React,{useCallback, useState}from "react";
import { SwmcFooter } from "footer-component";
import "./index.css";

const CustomFooter =()=>{
  return (
    <div className="footerbody">
      <SwmcFooter type="swmc"
      showTacanewLogo={true}
      nydisclaimer={false}/>
    </div>
  );
};
export default CustomFooter