/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import "../css/loanOfferings.css";
import parse from 'html-react-parser';
const loanOfferings = ({dataJson,dataJson2}) => {
    const { tableHeadings,rows,featuretableheading } = dataJson;
    const {disclaimer1,disclaimer2,disclaimer3,disclaimer4 } = dataJson2;
    return (
        <>
            <Container className="RenoOffering">
                <div className="text-center whitetext loan-heading" style={{ backgroundColor: "#083f88" }}>
                    <h5>Our Renovation Loan Offerings</h5>
                </div>
                <Row className="justify-content-center table-row py-3">
                    <Col xs={12} sm={12} md={12} lg={12} className="px-0 pb-3">
                    <Table bordered responsive="md">
                                <thead>
                                <tr>
                                        {tableHeadings.map((heading, index) => (
                                            <th className="th-heading" key={index} style={heading.style}>{parse(heading.label)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map((row, index) => (
                                        <tr key={index}>
                                            <td>{parse(row.type)}</td>
                                            <td>{parse(row.HomeStyle)}</td>
                                            <td>{parse(row['203kLimited'])}</td>
                                            <td>{parse(row['203kFull'])}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                    </Col>
                </Row>

            </Container>
            <Container>
                <Row>
                    <div className="disclaimer-text"> 
                        <p>{disclaimer1}</p>
                        <p>{parse(disclaimer2)}</p>
                        <p>{disclaimer3}</p>
                        <p>{disclaimer4}</p>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default loanOfferings;