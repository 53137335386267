import React, { lazy, Suspense, useState, useEffect } from "react";
import './App.css';
import Header from './components/Header';
import Footer from './components/CommenFooterComponent/index';
import Banner from './components/bannerNew';
import Body from './components/Body';
import Contact from './components/ContactUs';
import UseRenovationLoan from './components/useRenovationLoan';
import LoanOfferings from './components/loanOfferings';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";
import content from "./components/content.json"; // Import the JSON file
function App() {
  const isProduction = process.env.NODE_ENV === "production"

  const [dataJson, setDataJson] = useState(null); // Set initial state as null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch (process.env.REACT_APP_CDN_IMG + "renoswmc.json");
        const jsonData = await response.json();
        setDataJson(jsonData);
      } catch (error) {
        console.error('Error fetching links:', error);
        setDataJson(content); // Use local JSON data if fetching fails
      }
    };

    if (!dataJson) {
      fetchData(); // Fetch JSON only if the links data is not available
    }
  }, [dataJson]);

  useEffect(() => {
    if (!dataJson) {
      setDataJson(content); 
    }
  }, []);

  return (
      <CacheBuster
      currentVersion={appversion.version}
      isEnabled={isProduction} 
      isVerboseMode={false} 
      metaFileDirectory={"."} 
    >
       {dataJson ? (
      <>
      <Header dataJson={dataJson.body}/>
      <Banner dataJson={dataJson.banner}/>
      <Body dataJson={dataJson.body}/>
      <UseRenovationLoan dataJson={dataJson.feature}/>
      <LoanOfferings dataJson={dataJson.tableData} dataJson2={dataJson.disclaimer}/> 
      <Contact />
      <Footer />
      </>
        ) : (
          <div>Loading...</div>
        )}
      </CacheBuster>
  );
}

export default App;
