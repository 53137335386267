/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import { Container } from "react-bootstrap";
import "../css/banner.css";

const bannerNew = ({dataJson}) => {
    const { title, title2, title3, titleMobile, title2Mobile, title3Mobile, btnName, para } = dataJson;
    return (
        <>
        <div className="banner">
            <Container>
                    <div className="heading text-center">
                    <h1>{title}</h1>
                    <h1><span>{title2}</span></h1>
                    </div>
                    <div className="banner-para text-center pt-4 pb-5">
                    <h4>
                    {para}
                    </h4>
                    </div>
            </Container>
        </div>
        </>
    );
}

export default bannerNew;