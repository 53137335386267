/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, { useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import UserWayAccessibilityMenu from "./UserWayAccessibilityMenu";
import { isMobileOrTablet } from "./Utils";
import "../css/header.css";

const HeaderNavbar = () => {
    const [navbar, setNavbar] = useState(false);
    const isMobileOrTabletDevice = isMobileOrTablet();

    let prevScrollPos = window.scrollY;

    window.onscroll = function () {
        const currentScrollPos = window.scrollY;
        const menuNavbar = document.getElementById("menuNavbar");

        if (currentScrollPos === 0) {
            menuNavbar.classList.remove("navbarTransition");
            setNavbar(false);
        } else {
            if (prevScrollPos > currentScrollPos) {
                // Perform your desired actions for scrolling up here
                setNavbar(true);
            } else if (prevScrollPos < currentScrollPos) {
                // Perform your desired actions for scrolling down here
                if (!isMobileOrTabletDevice) {
                    setNavbar(true);
                } else {
                    setNavbar(false);
                    menuNavbar.classList.add("navbarTransition");
                }
            }
        }

        prevScrollPos = currentScrollPos;
    };

    return (
        <Navbar
            id="menuNavbar"
            collapseOnSelect
            fixed="top"
            className={navbar ? "navbar active" : "navbar"}
            variant="dark"
            expand="lg"
        >
            <Container className="navbarContainer">
                <Navbar.Brand>
                    <div className="d-flex">
                        <div>
                            <a href="https://www.swmc.com" target="_blank" rel="noopener noreferrer" title="Sun West Mortgage Logo">
                                <Image
                                    src="https://resources.swmc.com/swmc-images/sunwest-trust-white-logo.png"
                                    alt="Sun West Logo"
                                    className="swmcLogo img-fluid"
                                />
                            </a>
                        </div>
                        <UserWayAccessibilityMenu color="white" width={40} height={40} />
                    </div>

                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default HeaderNavbar;