/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 */
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Button, Col } from "react-bootstrap";
import "../css/contactus.css";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";

export default function Contactus() {

    const [node, setNode] = useState(null);
    const formRef = useCallback((node) => {
        // node refers to the name input field in the BorrowerLeadGenerationTool
        setNode(node);
    }, []);

    useEffect(() => {
        if (node) {

            const messageInput = document.getElementById("message");

            messageInput.placeholder = "Message";
            messageInput.ariaPlaceholder = "Message";
            const iconSpan = document.createElement("span");
            iconSpan.classList.add("message-icon-new");
            messageInput.parentNode.insertBefore(iconSpan, messageInput.nextSibling);

            const emaiInput = document.getElementById("email");
            emaiInput.placeholder = "Email ID";
            emaiInput.ariaPlaceholder = "Email ID";
            const iconSpan1 = document.createElement("span");
            iconSpan1.classList.add("email-icon-new");
            emaiInput.parentNode.insertBefore(iconSpan1, emaiInput.nextSibling);

            const phoneInput = document.getElementById("phone");
            phoneInput.placeholder = "Phone Number";
            phoneInput.ariaPlaceholder = "Phone Number";
            const iconSpan2 = document.createElement("span");
            iconSpan2.classList.add("phone-icon-new");
            phoneInput.parentNode.insertBefore(iconSpan2, phoneInput.nextSibling);

            const firstnameInput = document.getElementById("firstName");
            const iconSpan3 = document.createElement("span");
            iconSpan3.classList.add("name-icon-new");
            firstnameInput.parentNode.insertBefore(iconSpan3, firstnameInput.nextSibling);

            const lastnameInput = document.getElementById("lastName");
            const iconSpan4 = document.createElement("span");
            iconSpan4.classList.add("name-icon-new");
            lastnameInput.parentNode.insertBefore(iconSpan4, lastnameInput.nextSibling);


        }
    }, [node]);

    return (
        <>
            <Container>
                <Row className="borrtoolrow">

                    <Col className="borrleadtoolcol" xs={11} sm={12} md={12} xl={12} xxl={12} lg={12}>
                        <div className="request">
                            <p className="requestpara">Contact Us</p>
                        </div>
                        <BorrowerLeadGenerationTool
                            leadSource="NET001"
                            branch="058"
                            showModalBackBtn={false}
                            ref={formRef}
                            //   loanOfficerUid={loanOfficerUid}
                            mainBtnName="Send Message"
                            domainNameConsent="swmc.com"
                            language="en"
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}
